'use client'

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Bitcoin, Cpu, Lock, Wifi, BarChart2, Zap } from 'lucide-react'

const PageContainer = styled.div`
  min-height: 100vh;
  background-color: #000000;
  color: #f7931a;
  overflow: hidden;
  position: relative;
  font-family: Arial, sans-serif;
`

const GridBackground = styled(motion.div)`
  position: absolute;
  inset: 0;
  background-image: linear-gradient(to right, #f7931a22 1px, transparent 1px),
                    linear-gradient(to bottom, #f7931a22 1px, transparent 1px);
  background-size: 20px 20px;
`

const ContentContainer = styled.div`
  position: relative;
  z-index: 10;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`

const Header = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`

const MainContent = styled(motion.main)`
  width: 100%;
  max-width: 42rem;
  background-color: rgba(8, 25, 46, 0.8);
  border: 2px solid #f7931a;
  box-shadow: 0 0 20px #f7931a, 0 0 30px #f7931a, inset 0 0 20px #f7931a;
  padding: 2rem;
  border-radius: 0.5rem;
`

const Title = styled(motion.h1)`
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1.5rem;
  white-space: nowrap;
  overflow: hidden;
`

const EmailLink = styled(motion.a)`
  color: #00ff00;
  text-decoration: none;
  transition: all 0.3s ease;

  &:hover {
    color: #66ff66;
    text-shadow: 0 0 10px #00ff00, 0 0 20px #00ff00;
  }
`

const Footer = styled.footer`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
`

const IconGroup = styled.div`
  display: flex;
  gap: 1rem;
`

export default function BitcoinCyberpunkPage() {
  const [currentTime, setCurrentTime] = useState('')

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date().toLocaleTimeString())
    }, 1000)
    return () => clearInterval(timer)
  }, [])

  return (
    <PageContainer>
      <GridBackground
        animate={{
          backgroundPosition: ['0px 0px', '0px 20px']
        }}
        transition={{
          repeat: Infinity,
          duration: 1,
          ease: 'linear'
        }}
      />
      
      <ContentContainer>
        <Header>
          <motion.div
            animate={{ scale: [1, 0.95, 1] }}
            transition={{ repeat: Infinity, duration: 2 }}
          >
            <Bitcoin size={48} color="#f7931a" />
          </motion.div>
          <motion.div
            animate={{ x: [-2, 2, -2, 2, 0] }}
            transition={{ repeat: Infinity, duration: 0.5 }}
          >
            <strong>{currentTime}</strong>
          </motion.div>
        </Header>

        <MainContent
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Title
            animate={{ textShadow: ['0 0 10px #f7931a', '0 0 20px #f7931a', '0 0 10px #f7931a'] }}
            transition={{ repeat: Infinity, duration: 2 }}
          >
            BITCOIN Is The Future
          </Title>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
            style={{ fontSize: '1.25rem', textAlign: 'center', marginBottom: '2rem' }}
          >
            If you wish to own this domain, contact: {' '}
            <EmailLink href="cryptodegenerator@proton.me">
              cryptodegenerator@proton.me
            </EmailLink>
          </motion.p>
          <motion.div
            animate={{ x: [-2, 2, -2, 2, 0] }}
            transition={{ repeat: Infinity, duration: 0.5 }}
            style={{ fontSize: '1.125rem', textAlign: 'center' }}
          >
            Payments accepted in BTC
          </motion.div>
        </MainContent>

        <Footer>
          <IconGroup>
            {[Cpu, Lock, Wifi].map((Icon, index) => (
              <motion.div
                key={index}
                animate={{ opacity: [0.5, 1] }}
                transition={{ repeat: Infinity, duration: 1, delay: index * 0.2 }}
              >
                <Icon color="#00ff00" />
              </motion.div>
            ))}
          </IconGroup>
          <IconGroup>
            {[BarChart2, Zap].map((Icon, index) => (
              <motion.div
                key={index}
                animate={{ opacity: [0.5, 1] }}
                transition={{ repeat: Infinity, duration: 1, delay: index * 0.2 }}
              >
                <Icon color="#00ff00" />
              </motion.div>
            ))}
          </IconGroup>
        </Footer>
      </ContentContainer>
    </PageContainer>
  )
}